const migrationService = [
  {
    type: 'cloudmig',
    title: 'Cloud Migration',
    content:
      "It is the process of migrating data, apps or other business elements from an establishment's on-site computers " +
      'to the cloud, or moving them from one cloud environment to another.' +
      'with a wrist-friendly smartwatch app.',
  },
  {
    type: 'digitalmig',
    title: 'Digital Product or Software Migration',
    content:
      'CodeMyMobile assures you to perform the full migration of your product to the technology and platform you want.',
  },
  {
    type: 'enterprisemig',
    title: 'Enterprise Platform Migration',
    content:
      'With our working experience with diverse CRMs, CMSs, ERP, and custom enterprise tools, we are fully competent to ' +
      'initiate a painless migration process for your data, business logic, and more.',
  },
  {
    type: 'webappmig',
    title: 'Website and Web App Migration',
    content:
      'Whether the need is as small as migration your website and web apps between servers/hosts or as big ' +
      'as changing the entire technology of your web platforms, we are your best bet. We take care of your bandwidth, ' +
      'performance, and functional needs alongside maintaining features and data of your web entity as is.',
  },
  {
    type: 'databasemig',
    title: 'Database Migration',
    content:
      'We convert the data into a common format that can be output from the old database and input into the new database, ' +
      'reorganise and redesign, etc. Database migration is prone to downtime but our team full of experience knows exactly ' +
      'how to keep it minimal and at the time where the minimum number of users are affected. ',
  },
  {
    type: 'datamig',
    title: 'Data Migration',
    content:
      "It is considered at the toughest sort of migration, and that's why it requires special care." +
      ' We strategically plan and perform this essential task by taking multiple measures for the safety of your data.',
  },
  {
    type: 'applicationmig',
    title: 'Application Migration',
    content:
      'With expertise in the usage of middleware products to bridge gaps, which is a special requirement in migrating the ' +
      'applications, our process to you would feel like seamless as butter. ',
  },
];

export default migrationService;
